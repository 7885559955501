export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ifShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      }
    }
  },
  mounted() {},
  methods: {
    btnAction(event, index) {
      this.$emit('guideToVerifyAction', {
        'event': event,
        'index': index
      });
    }
  }
};