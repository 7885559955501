import { mapState } from 'vuex';
import { personIdMatchResult } from '@/api';
import { GoBack, mdToast } from '../../../utils/tools';
import MDRouter from '../../../service/router';
import IdentityAlert from '../../../components/identity/alert.vue';
export default {
  data() {
    return {
      name: '',
      id_card_number: '',
      agreement_status: false,
      showGuide: true,
      extraPaddingTop: 0 // 默认顶部无padding 但是版本小于62409101版本 需要设置为88px  之前版本手动向上偏移了88px
    };
  },
  components: {
    IdentityAlert
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    submitEnabled() {
      if (!this.name) return false;
      if (!this.id_card_number) return false;
      return true;
    }
  },
  mounted() {
    document.title = '个人实名认证';

    // eslint-disable-next-line no-undef
    graft.app({
      functionName: "onGetUserInfo",
      functionParams: {},
      callBack: e => {
        console.log('e------>', e);
        this.extraPaddingTop = Number(e.data.build) < 62409101 ? 44 : 0;
      }
    });
  },
  methods: {
    guideAction(result) {
      this.showGuide = false;
      if (Number(result.index) == 0) {
        GoBack();
      }
    },
    // 同意协议
    agreement() {
      this.agreement_status = !this.agreement_status;
    },
    jumpAgreement() {
      const url = "https://m.modian.com/protocal/2/16";
      MDRouter.Link(url);
    },
    async start() {
      if (this.submitEnabled == false) {
        return;
      }
      if (!this.name) return mdToast('请输入姓名');
      if (!this.id_card_number) return mdToast('请输入身份证号');
      if (!this.agreement_status) return mdToast('请阅读并同意实名认证用户服务协议');
      if (!this.isCardNo(this.id_card_number)) return;
      const {
        status,
        message
      } = await personIdMatchResult({
        real_name: this.name,
        id_card: this.id_card_number
      });
      if (status === 0) {
        this.$router.push({
          path: `/identity/authResult/${this.userId}`,
          query: {
            from: 'verify',
            result: 'success',
            message: message
          }
        });
      } else {
        this.$router.push({
          path: `/identity/authResult/${this.userId}`,
          query: {
            from: 'verify',
            result: 'fail',
            message: message
          }
        });
      }
    },
    isCardNo(card) {
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (reg.test(card) === false) {
        mdToast('身份证输入不合法');
        return false;
      } else {
        return true;
      }
    }
  }
};